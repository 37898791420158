/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ACTION_TYPES, getPriceStr, getFloorStr, toTitleCase } from "./commercialutils"

const CommercialSeoResults = ({ description, lang, meta, title, searchParams, location }) => {

  const {areas, bedrooms, pType, type, price, floor, commType } = searchParams;

  const actionStr = ACTION_TYPES[pType];
  const priceStr = getPriceStr(price)
  const floorStr = getFloorStr(floor)
  const bedRooms = searchParams.bedrooms

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  let propertyType = ""

  if(type == "") {
      propertyType = "commercial property"
  } else {
    if(type == "shop")
      propertyType = ` Shops `;
    else if(type == "office")
      propertyType = ` Offices `;
    else if(type == "industrial")
      propertyType = ` Industrial property `;
    else if(type == "upperpart")
      propertyType = ` Shop with upper parts `;
    else if(type == "retail")
      propertyType = ` Retail property `;
    else if(type == "land")
      propertyType = ` Development land `;
    else
      propertyType = (type);
  }

  let desc = "";

  desc = "Looking for "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) +"? Try Martyn Gerrard. Contact our residential property specialists, We help you with all your property needs.";

  if (floor.min || floor.max){
    desc = "Find "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + floorStr +". We specialise in property"+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+", contact us for more information.";
  }

  if (price.min || price.max){
    desc = "Check the latest collections of "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + priceStr +", book a viewing for interested property. Our property experts are here to help you find the right property.";
  }

  if (type){
    desc = "Find the wide range of "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) +". Contact us for more detail about the properties in London.";
  }

  if ((floor.min || floor.max) && (price.min || price.max)){
    desc = "Find "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + floorStr + priceStr +". Contact Martyn Gerrard for more information about the property.";
  }

  if ((floor.min || floor.max) && type){
    desc = "Look into "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + floorStr +", Book an appointment with one of our specialists today to find the perfect "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' '))+".";
  }

  if ((price.min || price.max) && type){
    desc = "Explore "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + priceStr +" with Martyn Gerrard, Contact us and get assisted for all your property needs.";
  }

  if ((floor.min || floor.max) && (price.min || price.max) && type){
    desc = "Discover "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + floorStr + priceStr +" with Martyn Gerrard, our experts help you find the perfect "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' '))+".";
  }

    let titlePropertyType = ""

    if (type) {
      if(type == "shop")
        titlePropertyType = ` Shops `;
      else if(type == "office")
        titlePropertyType = ` Offices `;
      else if(type == "industrial")
        titlePropertyType = ` Industrial property `;
      else if(type == "upperpart")
        titlePropertyType = ` Shop with upper parts `;
      else if(type == "retail")
        titlePropertyType = ` Retail property `;
      else if(type == "land")
        titlePropertyType = ` Development land `;
      else
        titlePropertyType = capitalize(type);
    } else {
      titlePropertyType = ` Commercial property `;
    }
    
    let bedroomType = ""

    if ( bedrooms ) {
      bedroomType = bedrooms + ' + Bedroom '
    }

    let descTitle = bedroomType + (titlePropertyType.replace(/-/g, ' ')) + actionStr + toTitleCase(areas.replace(/-/g, ' '))+ floorStr+ priceStr

  // console.log("Props Desc => ", desc);
  var currentURL = '';
  if (location !== undefined && location.pathname) {
    currentURL = process.env.GATSBY_SITE_URL + location.pathname;
  }

  description = desc

  // set no index for result pagination
  let hasPagination = false;
  if (location !== undefined && location.pathname) {
    let pageStr = location.pathname.split('page-');
    hasPagination = pageStr[1] || false;
    if (pageStr[0] !== undefined) {
     currentURL = process.env.GATSBY_SITE_URL + pageStr[0];
    }
  }

  if (!currentURL.endsWith('/'))
        currentURL = currentURL + '/';


  let metaItems = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: descTitle,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: `Martyn Gerrard`,
    },
    {
      name: `twitter:title`,
      content: descTitle,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ].concat(meta);


  if ( hasPagination ) {
    // metaItems.push({
    //   name: `robots`,
    //   content: `noindex`,
    // })
  }

  if ( currentURL ) {
    metaItems.push({
      name: `og:url`,
      content: currentURL,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
          {
              href: currentURL,
              rel: "canonical"
          }
      ]}
      title={descTitle}
      titleTemplate={`%s | Martyn Gerrard`}
      meta={metaItems}
    />
  )
}

CommercialSeoResults.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  searchParams: [],
}

CommercialSeoResults.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  searchParams: PropTypes.any,
}

export default CommercialSeoResults
